export const stores = [
  {
    city: "Araxá",
    address: "Av. Aracely de Paula, 1005 – Loja 23 – Centro",
    phone: "(34) 3612-9778",
    link: "https://hakunabatataaraxa.saipos.com/home",
  },

{
    city: "Arcos",
    address: "Rua Getúlio Vargas – N°416 – Centro",
    phone: "(37) 99998-1890",
    link: "https://hakunaarcos.saipos.com/home",
  },

{
    city: "Barbacena",
    address: "Rua Marechal Floriano Peixoto, 359, loja 19 e 20 Pontilhão, Bahamas shopping",
    phone: "(32) 99813-9322",
    link: "https://hakunabarbacena.saipos.com/home",
  },
  {
    city: "Belo Horizonte - Barreiro",
    address: "Av. Afonso Vaz de Melo, 640, Barreiro - Belo Horizonte MG",
    phone: "(31) 9720-1610",
    link: "",
  },
  {
    city: "Belo Horizonte – Coração Eucaristico",
    address: "Rua Coração Eucaristico, 311",
    phone: "Indisponível",
    link: "",
  },
{
    city: "Belo Horizonte - Savassi",
    address: "Rua Pernambuco 1097 Loja 3, Savassi – Belo Horizonte MG",
    phone: "(31) 3785-1097",
    link: "https://menu.ifood.com.br/hakunabatatasavassi",
  },
{
    city: "Belo Horizonte – Shopping Estação",
    address: "Av. Cristiano Machado, 11833 – Loja 3086",
    phone: " (31) 3656-5100",
    link: "https://www.ifood.com.br/delivery/belo-horizonte-mg/hakuna-batata---shopping-estacao-bh-venda-nova/8c8d5d01-dbf5-4370-86b4-9ba4a5abb7da",
  },

{
    city: "Belo Horizonte – Buritis",
    address: "Av. Professor Mário Werneck, 1360 – Loja 117",
    phone: "(31) 2573-0117",
    link: "https://menu.ifood.com.br/a2810e25-d1ae-4079-841d-69269797c38c",
  },

{
    city: "Bom Despacho",
    address: "Rua Dr. Miguel Gontijo, n° 280, centro, Bom Despacho.",
    phone: " (37) 98849-7551",
    link: "https://hakunabomdespacho.saipos.com/",
  },

{
    city: "Cataguases",
    address: "Praça Manoel Inácio Peixoto, 96, Loja 2b, Centro",
    phone: "(32) 99851-0599",
    link: "https://hakunabatatacataguases.saipos.com/home",
  },

  {
    city: "Contagem",
    address: "Avenida General David Sarnoff, 5160 - loja 30 A mznino1 - Cidade Industrial",
    phone: "Indisponível",
    link: "https://www.ifood.com.br/delivery/contagem-mg/hakuna-contagem-cidade-industrial/9d62e233-8f1e-4954-ab88-c73b89ae5e53",
  },

  {
    city: "Contagem - Big Shopping",
    address: "Av. João César de Oliveira, 1275 - Eldorado.",
    phone: "(31) 97203-8307",
    link: "https://www.ifood.com.br/delivery/contagem-mg/hakuna-batata-big-shopping-eldorado/17f439be-d135-4f22-a8d8-cb5f13bc1a5e",
  },

  {
    city: "Divinópolis – Shopping",
    address: "Rua Moacir Jose Leite, Loja 277, 100 – Santa Clara",
    phone: "37 9169-3937",
    link: "https://www.ifood.com.br/delivery/divinopolis-mg/hakuna-batata-shoping-jardim-nova-america/872f587a-c194-4b6a-806c-5fc7ceb33321",
  },
{
      city: "Formiga",
      address: "Praça Ferreira Pires, 121 - Centro, Formiga - MG",
      phone: "(37) 3322-8104",
      link: "https://www.ifood.com.br/delivery/formiga-mg/hakuna-batata-centro/f01787f1-45fc-457d-8c5b-fbe5d46509a7",
  },
  
{
    city: "Governador Valadares",
    address: "Avenida, R. 7 de Setembro, 3500 - Centro",
    phone: "(33) 9934-1731",
    link: "https://www.ifood.com.br/delivery/governador-valadares-mg/hakuna-batata---gv-shopping-centro/dc41f34d-f9a8-49cd-bed4-efb01708c8c9",
  },

{
    city: "João Monlevade",
    address: "Avenida Getúlio Vargas, 4507 lojas 1 e 2 – Carneirinhos",
    phone: " (31) 99898-8300",
    link: "https://www.ifood.com.br/delivery/joao-monlevade-mg/hakuna-batata-carneirinhos/28ca491b-b9ad-4584-9596-85fa08c60d81",

  },
{
    city: "Juiz de Fora - Bahamas",
    address: "AV. Barão do rio branco, 3760 – Loja 17",
    phone: "(32) 99957-9348",
    link: "https://www.ifood.com.br/delivery/juiz-de-fora-mg/hakuna-batata-alto-dos-passos/123eee8d-6020-4341-a6d4-146e2116a4c1",

  },
  {
    city: "Juiz de Fora - Shopping Jardim Norte",
    address: "Avenida Brasil, 6345 - Loja 2028A - Mariano Procópio",
    phone: "(32) 99994-5366",
    link: "https://www.ifood.com.br/delivery/juiz-de-fora-mg/hakuna-jardim-norte-mariano-procopio/ca3481af-97c6-443b-a0ce-1f308c7b3cca?utm_medium=share",

  },
  {
    city: "Juiz de Fora - Barão do Retiro",
    address: "Av Doutor Francisco Alves de Assis - Bahamas MIX",
    phone: "Indisponível",
    link: "",

  },
  {
    city: "Lagoa da Prata",
    address: "AV BENEDITO VALADARES, 937, LOJA 02 - Centro",
    phone: "(37) 98472-9463",
    link: "https://hakunalagoadaprata.saipos.com/home",

  },
  {
    city: "Manhuaçu",
    address: "Av Getúlio Vargas, 730 - Coqueiro",
    phone: "(33) 9876-5212",
    link: "https://hakunamanhuacu.saipos.com/home",
  },
  {
    city: "Montes Claros",
    address: "Av. Donato Quintino, 90 - Cidade Nova, SHOPPING MONTES CLAROS.",
    phone: "Indisponível",
    link: "",
  },
{
    city: "Muriaé",
    address: "Rua Coronel Domiciano, 43 – Centro",
    phone: "(32) 3722-4258",
    link: "https://hakunabatata.saipos.com/hakuna-batata-muriae",

  },
{
    city: "Nova Serrana",
    address: "Avenida Dom Cabral, numero 30, loja 5, Bairro: jardim do Lagoa",
    phone: " (37) 3225-7930",
    link: "https://hakunanovaserrana.saipos.com/home",

  },
  {
    city: "Oliveira",
    address: "PC Doutor Jose Ribeiro Silva, 93 - Centro",
    phone: "(37) 99942-6924",
    link: "https://hakunabatataoliveira.saipos.com/home",
  },
{
    city: "Para de Minas",
    address: "Rua Benedito Valadares, 501 A – Loja 2, Centro",
    phone: " (37) 3259-0493",
    link: "https://www.ifood.com.br/delivery/para-de-minas-mg/hakuna-batata-centro/6ccade24-2fe1-4bde-98bd-6634b9fce7df",

  },
  {
    city: "Passos",
    address: "Praça Geraldo da Silva Maia, 10 - Centro, Passos - MG",
    phone: "(35) 99957-9988",
    link: "https://hakunabatatapassos.saipos.com/home",
},
{
    city: "Patos de Minas",
    address: "Olegario Maciel, 63 - Centro",
    phone: " (34) 99929-0950",
    link: "https://www.cardapiodigital.io/4db3b537-7fb9-4bcb-96d0-001bbeb6e823/catalog",

  },
{
    city: "Patrocinio",
    address: "AVENIDA RUI BARBOSA , 201, Centro",
    phone: " (34) 9941-3815",
    link: "https://hakunapatrocinio.saipos.com/home",

  },
  {
    city: "Piumhi",
    address: "Rua Bossuet Costa, 100 - Centro, Piumhi - MG",
    phone: "(37) 99868-1840",
    link: "https://hakunabatatapiumhi.saipos.com/home",

  },
  {
    city: "Poços de caldas",
    address: "Av. Silvio Monteiro dos Santos, 180 - Vale dos Antas",
    phone: "Indisponível",
    link: "",
  },
{
    city: "Ponte Nova",
    address: "Av. Francisco Vieira Martins, 508 – Loja 01 – Palmeiras",
    phone: " (31) 3817-1922",
    link: "https://www.ifood.com.br/delivery/ponte-nova-mg/hakuna-batata-palmeiras/d849bda4-a472-4844-8fd7-2a4d1114a23c",

  },
{
    city: "São João Del-Rei",
    address: "Rua Padre José Maria Xavier, 131, Centro",
    phone: " 32 8482-1793",
    link: "https://hakunasaojoaodelrei.saipos.com/home",

  },

  {
    city: "São Sebastião do Paraíso",
    address: "Comendador João Alves, 12B - CENTRO",
    phone: "(35) 99916-2419",
    link: "https://Hakunassp.saipos.com",

  },
{
    city: "Sete Lagoas",
    address: "Praça Alexandre Lanza, 10, Centro",
    phone: " (31) 98823-2124",
    link: "https://hakunasetelagoas.saipos.com/home",

  },
{
    city: "Teófilo Otoni",
    address: "Rua Teodorico Tourinho, 275 Centro",
    phone: " (33) 98827-3253",
    link: "https://hakunateofilootoni.saipos.com/",

  },
{
    city: "Ubá",
    address: "Praça São Januário, 54, centro",
    phone: " (32) 3532-9079",
    link: "https://hakunauba.saipos.com/home",

  },
{
    city: "Varginha",
    address: "R Humberto pizzo, 999 – loja 13 – jardim petropolis",
    phone: " (35) 99886-2552",
    link: "https://www.ifood.com.br/delivery/varginha-mg/hakuna-batata---via-cafe-jardim-canaa/6a7b72e4-91e6-4b71-9b47-658ada79c798",

  },
{
    city: "Viçosa – Matriz",
    address: "Av. P.H. Rolfs, 251 – Loja A – Centro",
    phone: " (31) 3891-9142",
    link: "https://hakunavicosa.saipos.com",

  },
{
    city: "Visconde do Rio Branco",
    address: "Praça 28 de setembro, 552 – Loja 10 – Centro",
    phone: " (32) 99833-9344",
    link: "https://hakunabatatavrb.saipos.com/home",

  },
];
